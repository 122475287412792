<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  export let dataMenu;
  export let propertyId;
  export let backgroundColorCards;
  export let colorLetter;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleRoomServiceDetail = (data) => {
    localStorage.setItem("menuRoomId", JSON.stringify(data.id));
    push(`/properties/${propertyId}/room-services/detail/${data.id}`);
  };
</script>

<div
  class=" card card-menu-room-service"
  use:styling={{ background: backgroundColorCards }}
  on:click={handleRoomServiceDetail(dataMenu)}
>
  <div class="card__image-cont">
    {#if dataMenu.photoUrl && dataMenu.disabledForTime}
      <img class="card__image" src={dataMenu.photoUrl} alt="" />
    {:else if dataMenu.photoUrl && !dataMenu.disabledForTime}
      <img class="card__image off-active" src={dataMenu.photoUrl} alt="" />
    {/if}
  </div>
  <div
    class={!dataMenu.disabledForTime
      ? "card-content padding-top-off"
      : "card-content"}
  >
    <span class="card__title" use:styling={{ color: colorLetter }}>
      {dataMenu.name}
    </span>
    <div class="card__cont-text">
      <p class="card__text" use:styling={{ color: colorLetter }}>
        {dataMenu.subName}
      </p>
    </div>
    {#if dataMenu.photoUrl && !dataMenu.disabledForTime}
      <div class="out-of-service">
        <i class="material-icons">timer_off</i>
        <span>
          {$_("Out of service")}
        </span>
      </div>
    {/if}
  </div>
</div>

<style>
  .card-menu-room-service {
    position: relative !important;
    padding: 0px;
    height: fit-content !important;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    min-height: 60px;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    margin-top: 0px !important;
    opacity: 1;
    cursor: pointer;
  }

  .card__image {
    width: 100%;
    height: 146px;
    object-fit: cover;
    object-position: center center;
    border-radius: 4px 4px 0px 0px !important;
  }

  .card-content {
    padding: 16px !important;
  }

  .card__title {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .card__text {
    font-size: 0.875rem;
    color: #686c6d;
  }

  @media only screen and (min-width: 601px) {
    .card__image {
      height: 180px;
    }
  }

  @media only screen and (min-width: 992px) {
    .card__image {
      height: 196px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card__image {
      height: 215px;
    }
  }
  .off-active {
    filter: grayscale(1);
  }
  .out-of-service {
    position: absolute;
    bottom: 65px;
    left: 10px;
    background: #212121;
    padding: 5px 5px 2px 0px;
    border-radius: 4px;
    width: 160px;
  }
  .out-of-service > i {
    color: #f4f4f4;
    font-size: 16px;
    padding-left: 5px;
  }
  .out-of-service > span {
    font-size: 12px;
    color: #f4f4f4;
    text-transform: uppercase;
    position: relative;
    top: -3px;
    font-weight: 500;
  }
  .padding-top-off {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
</style>
